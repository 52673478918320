'use client';

import React from 'react';
import { useParams, usePathname } from 'next/navigation';
import NextImage from '../NextImage';
import NextLink from '../NextLink';
import Container from '../Container';
import SearchModal from '@src/components/Sections/SearchModal';
import Button from '../Button';
import ListItem from './ListItem';
import LanguageDropdown from './LanguageDropdown';
import ProductsListItem from './ProductsListItem';
import SideNavigation from './SideNavigation';
import { TopNavigationUIPropTypes } from './TopNavigation.types';
import { PAGE_LOCALES } from '@src/typescriptGlobals/constants';
export default function TopNavigationUI({
  data
}: TopNavigationUIPropTypes) {
  const pathname = usePathname();
  const params = useParams<{
    locale: string;
  }>();
  const pages = data?.topNavigation?.items?.[0]?.navItemsCollection?.items;
  const [isSearchModel, setIsSearchModel] = React.useState(false);
  const [isHamburger, setIsHamburger] = React.useState(false);
  const onHandleModelToggle = () => {
    if (!isSearchModel) {
      setIsSearchModel(true);
      document.documentElement.classList.add('overflow-hidden');
      document.getElementsByClassName('aa-Input');
    } else {
      document.getElementsByClassName('search-modal-fade')?.[0]?.classList.add('search-modal-fade-out');
      setTimeout(() => setIsSearchModel(false), 350);
      document.documentElement.classList.remove('overflow-hidden');
    }
  };
  const onToggleMobileNav = () => setIsHamburger(!isHamburger);
  return <>
      <nav id='top-navigation' className='fixed left-[0] z-[100000] w-[100%] bg-background-secondary'>
        <Container HtmlTag='ul' className='top-0 !mb-[0] flex h-[7.5rem] items-center gap-[2rem] font-[400] md:gap-[2rem] 2xl:gap-[2.4rem] 2xl:text-[1.8rem]' data-sentry-element="Container" data-sentry-source-file="TopNavigationUI.tsx">
          <li className='mr-auto'>
            <NextLink href='/' title='Go to VALD Performance Homepage' className='relative z-[10000] flex h-[7.3rem] min-w-[20rem] items-center sm:min-w-[26rem]' locale={PAGE_LOCALES.includes(params?.locale) ? params?.locale : 'en'} data-sentry-element="NextLink" data-sentry-source-file="TopNavigationUI.tsx">
              <NextImage className='relative bottom-[0.1rem]' src={data?.topNavigation?.items?.[0]?.logo?.url} alt={data?.topNavigation?.items?.[0]?.logo?.description} aspectRatio={data?.topNavigation?.items?.[0]?.logo?.width / data?.topNavigation?.items?.[0]?.logo?.height} width={data?.topNavigation?.items?.[0]?.logo?.width} data-sentry-element="NextImage" data-sentry-source-file="TopNavigationUI.tsx" />
            </NextLink>
          </li>

          <ListItem page={pages?.find(page => page?.slug === '/sports')!} pathname={pathname} data-sentry-element="ListItem" data-sentry-source-file="TopNavigationUI.tsx" />
          <ListItem page={pages?.find(page => page?.slug === '/organizations')!} pathname={pathname} data-sentry-element="ListItem" data-sentry-source-file="TopNavigationUI.tsx" />
          <ListItem page={pages?.find(page => page?.slug === '/applications')!} pathname={pathname} data-sentry-element="ListItem" data-sentry-source-file="TopNavigationUI.tsx" />
          <ProductsListItem className='mr-auto' page={pages?.find(page => page?.slug === '/products')!} pathname={pathname} globalTextBlock={data?.globalTextBlock} data-sentry-element="ProductsListItem" data-sentry-source-file="TopNavigationUI.tsx" />
          <ListItem page={pages?.find(page => page?.slug === '#resources')!} pathname={pathname} data-sentry-element="ListItem" data-sentry-source-file="TopNavigationUI.tsx" />
          <ListItem page={pages?.find(page => page?.slug === '#support')!} pathname={pathname} data-sentry-element="ListItem" data-sentry-source-file="TopNavigationUI.tsx" />
          <LanguageDropdown className='group flex h-[100%] items-center' data-sentry-element="LanguageDropdown" data-sentry-source-file="TopNavigationUI.tsx" />
          <li className='hidden md:block'>
            <Button btnClassName='relative bottom-[0.1rem]' secondary btnText={pages?.find(page => page?.slug === '/contact')?.pageName} href='/contact' align='justify-start' data-sentry-element="Button" data-sentry-source-file="TopNavigationUI.tsx" />
          </li>
          <li onClick={onHandleModelToggle}>
            <svg className='cursor-pointer' width='20' height='20' fill='#FF7A00' viewBox='0 0 40 40' aria-hidden='true' data-sentry-element="svg" data-sentry-source-file="TopNavigationUI.tsx">
              <path d='M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z' data-sentry-element="path" data-sentry-source-file="TopNavigationUI.tsx"></path>
            </svg>
          </li>
          <li className='xl:hidden xl:locale-fr:block 3xl:locale-fr:hidden'>
            <button data-test='hamburger-button' id='menu-btn' aria-label='close button' className={`col-span-1 block h-[2.5rem] w-[1.5rem] cursor-pointer transition-all duration-300 focus:outline-none ${isHamburger ? 'mt-[-1rem]' : 'mt-[0rem]'}`} onClick={onToggleMobileNav}>
              <span className={`absolute left-[0rem] top-[0rem] h-[0.2rem] w-[2.2rem] transform bg-background-primary transition-all duration-500 ${isHamburger ? 'w-[2.6rem] translate-y-[0.7rem] rotate-45' : 'translate-y-[0.35rem]'}`} />
              <span className={`absolute left-[0rem] top-[0rem] h-[0.2rem] w-[2.2rem] transform bg-background-primary transition-all duration-500 ${isHamburger ? 'opacity-0' : 'translate-y-[1.2rem] opacity-100'}`} />
              <span className={`absolute left-[0rem] top-[0rem] h-[0.2rem] w-[2.2rem] transform bg-background-primary transition-all duration-500 ${isHamburger ? 'w-[2.6rem] translate-y-[0.7rem] -rotate-45' : 'translate-y-[2.05rem]'}`} />
            </button>
          </li>
        </Container>
      </nav>

      {isSearchModel && <SearchModal onHandleModelToggle={onHandleModelToggle} globalTextBlock={data?.globalTextBlock} />}

      <SideNavigation list={pages} onToggleMobileNav={onToggleMobileNav} isHamburger={isHamburger} globalTextBlock={data?.globalTextBlock} data-sentry-element="SideNavigation" data-sentry-source-file="TopNavigationUI.tsx" />
    </>;
}